<template>
    <div class="d-flex flex-wrap">
        <template v-for="(option, i) in options" :key="i">
            <div class="form-check mb-2 me-3">
                <label class="form-check-label">
                    <input
                        type="checkbox"
                        class="form-check-input"
                        :value="option[option_key]"
                        v-model="checkedOptions"
                        @change="$emit('update:modelValue', this.checkedOptions)"
                        true-value="option[option_key]"
                    >
                    {{ option[option_label] }}
                </label>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FormCheckboxes',
    props: {
        modelValue: {
            type: Array
        },
        options: {
            type: Array
        },
        option_key: {
            type: String,
            default: 'key'
        },
        option_label: {
            type: String,
            default: 'label'
        },
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            checkedOptions: this.modelValue,
        }
    },
    watch: {
        modelValue() {
            this.checkedOptions = this.modelValue;
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
